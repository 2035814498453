<template>
  <div class="data-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="範本名稱" prop="name" align="center" width="594px" />
      <el-table-column label="範本類型" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.isDefault ? '系統預設' : '新增' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="範本用途" prop="type" align="center">
        <template slot-scope="scope">
          <span>{{ renderType(scope.row.type) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否有通知選用" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.hasBindNotifySetting ? 'action' : 'info'">{{ scope.row.hasBindNotifySetting ? '是' : '否' }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            :hideDelete="scope.row.isDefault"
            @edit="onRowEdit(scope.row)"
            @delete="onRowDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteRow"
    />

    <DeleteDialog
      v-if="modal.deleteSelected"
      title="有通知選擇此範本作為通知內容"
      content="刪除後，選取此範本的通知內容將改為隱藏，該通知不會發送此範本的通知內容。確定要刪除？"
      width="40%"
      @close="modal.deleteSelected = false"
      @delete="deleteRow"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
// import { DeleteDataAPI } from '@/api/module'
import { systemContentTemplateConfig } from '@/config/progressNotification'
import { useTable } from '@/use/table'
import { get, map } from 'lodash'
import { useRouter } from 'vue-router/composables'
import { DeleteProgressNotificationContentTemplate } from '@/api/progressNotification'

export default defineComponent({
  name: 'NotificationContentTemplateTable',
  components: { EmptyBlock },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const { shopId, dateFormat } = useTable()
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
      deleteSelected: false,
    })

    const displayData = computed(() => {
      const data = props.tableData
      const defaultKey = ['appointmentInfo:basic', 'shopInfo:basic', 'imageText:appointmentCreate', 'imageText:serviceBefore', 'imageText:serviceAfter']

      data.sort((a, b) => {
        const aIndex = defaultKey.indexOf(a.defaultKey)
        const bIndex = defaultKey.indexOf(b.defaultKey)

        if (aIndex === -1 && bIndex === -1) {
          return 0
        } else if (aIndex === -1) {
          return 1
        } else if (bIndex === -1) {
          return -1
        } else {
          return aIndex - bIndex
        }
      })
      return map(data, (i) => {
        return {
          id: i.id,
          name: i.name,
          isDefault: i.isDefault,
          type: i.type,
          hasBindNotifySetting: i.hasBindNotifySetting,
        }
      })
    })

    const onRowEdit = (row) => {
      router.push({
        name: 'ProgressNotificationContentTemplateEdit',
        params: { id: row.id },
      })
    }

    const onRowDelete = (row) => {
      selectRow.value = row
      if (row.hasBindNotifySetting) modal.deleteSelected = true
      else modal.delete = true
    }

    const deleteRow = async () => {
      const [, err] = await DeleteProgressNotificationContentTemplate({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      modal.deleteSelected = false
      emit('refresh')
    }
    const renderType = (type) => {
      return get(systemContentTemplateConfig[type], 'label')
    }
    return {
      modal,
      displayData,
      onRowEdit,
      onRowDelete,
      deleteRow,
      renderType,
    }
  },
})
</script>
